import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import SubscriptionsList from "./views/SubscriptionsList.vue";
import NewSchoolSubscriptionView from "./views/NewSchoolSubscription.vue";
import NewSchoolAdminView from "./views/NewSchoolAdmin.vue";
import NewDistrictAdminView from "./views/NewDistrictAdmin.vue";
import DomainsView from "./views/Domains.vue";
import VideosView from "./views/Videos.vue";
import HomeschoolSubscriptionsList from "./views/HomeschoolSubscriptions.vue";
import NewHomeschoolSubscriptionView from "./views/NewHomeschoolSubscription.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/subscriptions",
      name: "subscriptions",
      component: SubscriptionsList
    },
    {
      path: "/new-school-subscription",
      name: "new-school-subscription",
      component: NewSchoolSubscriptionView
    },
    {
      path: "/new-school-admin",
      name: "new-school-admin",
      component: NewSchoolAdminView
    },
    {
      path: "/new-district-admin",
      name: "new-district-admin",
      component: NewDistrictAdminView
    },
    {
      path: "/domains",
      name: "domains",
      component: DomainsView
    },
    {
      path: "/videos",
      name: "videos",
      component: VideosView
    },
    {
      path: "/homeschool-subscriptions",
      name: "homeschool-subscriptions",
      component: HomeschoolSubscriptionsList
    },
    {
      path: "/new-homeschool-subscription",
      name: "new-homeschool-subscription",
      component: NewHomeschoolSubscriptionView
    }
  ]
});
