
import { Component, Vue } from "vue-property-decorator";
import NewDistrictAdmin from "@/components/NewDistrictAdmin.vue"; // @ is an alias to /src

@Component({
  components: {
    NewDistrictAdmin
  }
})
export default class NewDistrictAdminView extends Vue {}
