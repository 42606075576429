
  import axios from "axios";
  import {Component, Vue} from "vue-property-decorator";
  import Datepicker from "vuejs-datepicker";


  interface OptionsItem {
    value: number,
    text: string
  }

  interface ServerParams {
    stateId: string;
    districtId: number;
  }


  function createDomainAPI(districtId: number, schoolId: number, domain: String) {
    return axios.post(`/api/admin/licenses/districts/${districtId}/domains`, {school_id: schoolId, domain});
  }

  function updateDomainAPI(districtId: number, domainRecordId: number, schoolId: number, domain: String) {
    console.log("UPDATE DOMAIN:",{districtId, domainRecordId, schoolId, domain});
    return axios.put(`/api/admin/licenses/districts/${districtId}/domains/${domainRecordId}`, {school_id: schoolId, domain});
  }

  function deleteDomainAPI(districtId: number, domainRecordId: number) {
    console.log("DEL DOMAIN:",{districtId, domainRecordId});
    return axios.delete(`/api/admin/licenses/districts/${districtId}/domains/${domainRecordId}`);
  }

  function getSchoolsAndDomainsFromServer(districtId: number) {
    return axios.get(`/api/admin/licenses/districts/${districtId}/schools-and-domains`);
  }

  function getFromServer(params: ServerParams) {
    return axios.get("/api/districts-and-schools", {params});
  }

  @Component({
    components: {
      Datepicker
    }
  })
  export default class NewSchoolSubscription extends Vue {
    loading: boolean = false;
    states: Array<OptionsItem> = [];
    stateId: string = 'TX';
    oldStateId: string = 'TX';
    districts: Array<OptionsItem> = [];
    districtId: number = 0;
    oldDistrictId: number = 0;
    schools: Array<OptionsItem> = [];
    districtDomains: Array<OptionsItem> = [];
    domains: Array<string> = [];
    plans: Array<OptionsItem> = [];
    planTokens: Array<string> = [];
    purchased?: boolean = false;
    skip_domain_validation?: boolean = false;
    comments?: string = '';
    postedError: boolean = false;
    postedErrorMessage: string = '';
    detachedSuccess: boolean = false;
    postedSuccess: boolean = false;
    error: string = '';
    errorRecords: Array<any> = [];
    invalidUsers: Array<any> = [];
    detachedUsers: Array<any> = [];
    newSubscriptions: any = [];
    fields: Array<any> = [];
    newRecordDomain: string = '';
    newRecordSchoolId: number = 0;
    createdRecord: any = {};

    data() {
      return {
        loading: false,
        domains: [],
        plans: [],
        districtId: 0,
        stateId: 'TX',
        states: [],
        districts: [],
        schools: [],
        planTokens: [],
        purchased: false,
        skip_domain_validation: false,
        comments: '',
        show: true,
        postedError: false,
        postedErrorMessage: '',
        detachedSuccess: false,
        postedSuccess: false,
        error: '',
        errorRecords: [],
        invalidUsers: [],
        detachedUsers: [],
        newSubscriptions: [],
        fields: [
          {key: 'school_id', label: 'School'},
          'domain',
          {key: 'edit', label: 'Edit'},
          {key: 'delete', label: 'Delete'}
        ],
        newRecordDomain: '',
        newRecordSchoolId: 0,
        createdRecord: {}
      }
    }

    mounted() {
      return this.loadItems();
    }

    onReset(evt?: any) {
      this.postedError = false;
      this.postedErrorMessage = '';
      this.detachedSuccess = false;
      this.postedSuccess = false;
      this.newRecordDomain = '';
      this.newRecordSchoolId = 0;
      this.createdRecord = {};
      if (evt){
        evt.preventDefault();
      }
      return this.loadItems().then(()=>this.loadDomains());
      /* Reset our form values */
      /* Trick to reset/clear native browser form validation state */
      // this.show = false;
      // this.$nextTick(() => { this.show = true });
    }

    resetNewDomain(evt?: any){
      this.postedError = false;
      this.postedErrorMessage = '';
      this.detachedSuccess = false;
      this.postedSuccess = false;
      this.newRecordDomain = '';
      this.newRecordSchoolId = 0;
      this.createdRecord = {};
    }

    stateChange() {
      if (this.oldStateId === this.stateId) {
        return;
      }
      this.oldStateId = this.stateId;
      this.districtId = 0;
      return this.loadItems();
    }

    districtChange() {
      if (this.oldDistrictId === this.districtId) {
        return;
      }
      this.oldDistrictId = this.districtId;
      return this.onReset();
    }

    loadItems() {
      this.loading = true;
      return getFromServer({districtId: this.districtId, stateId: this.stateId})
        .then(response => {
          this.loading = false;
          const data = response.data;
          this.states = data.states;
          this.districts = data.districts;
          this.schools = [{value: 0, text: 'no associated school'}].concat(data.schools||[]);
          this.domains = data.domains;
          this.planTokens = [];
          this.purchased = false;
          this.skip_domain_validation = false;
          this.comments = '';
        })
        .catch((error) => {
          this.loading = false;
          console.error("failed to get data from server:", error);
        });
    }

    deleteDomain(domainRec:any) {
      this.loading = true;
      this.postedError = false;
      return deleteDomainAPI(this.districtId, domainRec.domain_record_id)
        .then(()=>{
          this.loading = false;
          this.postedError = false;
          return this.loadDomains();
        })
        .catch((error:any)=> {
          this.postedSuccess = false;
          this.loading = false;
          console.error("ERROR IN DELETING DOMAIN:", error);
          this.postedError = true;
          this.postedErrorMessage = error.data;
          return this.loadDomains();
        });
    }

    updateDomain(domainRec:any) {
      this.loading = true;
      this.postedError = false;
      return updateDomainAPI(this.districtId, domainRec.domain_record_id, domainRec.school_id, domainRec.domain )
        .then(()=>{
          this.loading = false;
          this.postedError = false;
          return this.loadDomains();
        })
        .catch((error:any)=> {
          this.postedSuccess = false;
          this.loading = false;
          console.error("ERROR IN UPDATING DOMAIN:", error);
          this.postedError = true;
          this.postedErrorMessage = error.data;
          return this.loadDomains();
        });
    }

    createDomain() {
      this.loading = true;
      this.postedError = false;
      return createDomainAPI(this.districtId, this.newRecordSchoolId, this.newRecordDomain )
        .then((response)=>{
          this.postedSuccess = true;
          this.postedError = false;
          this.loading = false;
          this.createdRecord = response.data;
          return this.loadDomains();
        })
        .catch((error:any)=> {
          this.postedSuccess = false;
          this.loading = false;
          console.error("ERROR IN CREATING DOMAIN:", error);
          this.postedError = true;
          this.postedErrorMessage = error.data;
          return this.loadDomains();
        });
    }

    loadDomains() {
      this.loading = true;
      return getSchoolsAndDomainsFromServer(this.districtId)
        .then((response:any) => {
          this.loading = false;
          this.districtDomains = response.data;
        })
        .catch((error: any) => {
          this.loading = false;
          console.error("failed to get data from server:", error);
        });
    }
  }
