
import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import moment from "vue-moment";

function getFromServer(params: ServerParams) {
  return axios.get("/api/homeschool-subscriptions", {params});
}

interface TableColumns {
  label?: string;
  field?: string;
  filterable?: boolean;
}

interface ServerParams {
  columnFilters?: object;
  page?: number;
  perPage?: number;
  sort?: {
    field: string;
    type: string;
  };
}

interface VueTableParams extends ServerParams {
  currentPage?: number;
  currentPerPage?: number;
  columnIndex: number;
  sortType?: "string";
}

@Component({
  components: {
    Datepicker
  }
})

export default class Subscriptions extends Vue {
  totalRecords: number = 0;
  postedSuccess: boolean = false;
  postedError: boolean = false;
  postedMessage: string = '';
  totalRows: number = 0;
  rows: Array<object> = [];
  columns: Array<TableColumns> = [];
  serverParams: VueTableParams = {columnIndex: 1};

  // $router: any;
  mounted() {
    this.loadItems();
  }

  data() {
    return {
      columns: [
        {
          label: "Homeschool Name",
          field: "school_name",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Teachers",
          field: "teacher_emails",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Plan Tokens",
          field: "plan_tokens",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Start Date",
          field: "starts_at",
          filterable: true
        },
        {
          label: "End Date",
          field: "ends_at",
          filterable: true
        },
        {
          label: "Subscription Ids",
          field: "subscription_ids",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Seats",
          field: "seats",
          filterable: true
        },
        {
          label: "Token",
          field: "purchase_token",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: "Edit",
          field: "edit",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      postedSuccess: false,
      postedError: false,
      postedMessage: '',
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: ""
        },
        page: 1,
        perPage: 1000
      }
    };
  }

  updateParams(newProps: any) {
    this.serverParams = Object.assign({}, this.serverParams, newProps);
  }

  onPageChange(params: VueTableParams) {
    this.updateParams({page: params.currentPage});
    this.loadItems();
  }

  onPerPageChange(params: VueTableParams) {
    this.updateParams({perPage: params.currentPerPage, page: 1});
    this.loadItems();
  }

  onSortChange(params: VueTableParams) {
    this.updateParams({
      sort: {
        type: params.sortType,
        field: this.columns[params.columnIndex].field
      },
      page: 1
    });
    this.loadItems();
  }

  onEdit(homeschool: any) {
    this.$router.push({path: 'new-homeschool-subscription', query: {id: homeschool.school_id}});
  }

  onColumnFilter(params: VueTableParams) {
    params.page = 1;
    this.updateParams(params);
    this.loadItems();
  }

  // load items is what brings back the rows from server
  loadItems() {
    getFromServer(this.serverParams)
      .then(response => {
        console.log("RESP:", response.data);
        this.totalRecords = response.data.totalRecords;
        this.totalRows = response.data.totalRecords;
        this.rows = response.data.rows;
      })
      .catch(function (error) {
        console.error("failed to get data from server:", error);
      });
  }
}
