
import axios from "axios";
import {Component, Vue} from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

interface OptionsItem {
  value: number,
  text: string
}

interface newSubscriptionsForm {
  school_id: number;
  plans: Array<string>;
  starts_at: Date;
  ends_at: Date;
  purchased?: boolean;
  skip_domain_validation?: boolean;
  comments?: string;
}

interface ServerParams {
  stateId: string;
  districtId: number;
}

interface TableColumns {
  label?: string;
  field?: string;
}

function postToServer(params: newSubscriptionsForm) {
  return axios.post("/api/subscriptions/new-school-subscription", params);
}

function detachInvalidUsersFromSchool(schoolId: number) {
  return axios.post(`/api/schools/${schoolId}/domains/detach`);
}

function getFromServer(params: ServerParams) {
  return axios.get("/api/districts-and-schools", {params});
}

function loadPlanTokens() {
  return axios.get("/api/all-plan-tokens");
}

function getSchoolInfo(schoolId: number) {
  return axios.get(`/api/schools/${schoolId}`);
}

@Component({
  components: {
    Datepicker
  }
})
export default class NewSchoolSubscription extends Vue {
  loading: boolean = false;
  schoolId: number = 0;
  oldSchoolId: number = 0;
  schoolZIP: string = '';
  schoolAddress: string = '';
  states: Array<OptionsItem> = [];
  stateId: string = 'TX';
  oldStateId: string = 'TX';
  districts: Array<OptionsItem> = [];
  districtId: number = 0;
  oldDistrictId: number = 0;
  schools: Array<OptionsItem> = [];
  domains: Array<string> = [];
  startsAt: Date = new Date();
  endsAt: Date = moment().add(1, 'y').toDate();
  plans: Array<OptionsItem> = [];
  planTokens: Array<any> = [];
  purchased?: boolean = false;
  skip_domain_validation?: boolean = false;
  comments?: string = '';
  postedError: boolean = false;
  detachedSuccess: boolean = false;
  postedSuccess: boolean = false;
  error: string = '';
  errorRecords: Array<any> = [];
  invalidUsers: Array<any> = [];
  detachedUsers: Array<any> = [];
  newSubscriptions: any = [];
  rows: Array<object> = [];
  columns: Array<TableColumns> = [];

  data() {
    return {
      loading: false,
      domains: [],
      plans: [],
      districtId: 0,
      stateId: 'TX',
      states: [],
      selectedSchool: 0,
      schoolId: 0,
      oldSchoolId: 0,
      schoolZIP: '',
      schoolAddress: '',
      districts: [],
      schools: [],
      startsAt: new Date(),
      endsAt: moment().add(1, 'y').toDate(),
      planTokens: [],
      purchased: false,
      skip_domain_validation: false,
      comments: '',
      show: true,
      postedError: false,
      detachedSuccess: false,
      postedSuccess: false,
      error: '',
      errorRecords: [],
      invalidUsers: [],
      detachedUsers: [],
      newSubscriptions: [],
      rows: [],
      columns: [
        {
          label: "Plan Name",
          field: "plan_token"
        },
        {
          label: "Seats",
          field: "seats"
        }
      ]
    }
  }

  mounted() {
    return this.loadItems().then(this.loadPlanTokens);
  }

  onSubmit(evt: any) {
    evt.preventDefault();

    this.postedError = false;
    this.detachedSuccess = false;
    this.postedSuccess = false;
    this.errorRecords = [];
    this.invalidUsers = [];
    this.detachedUsers = [];

    let params = {
      school_id: this.schoolId,
      plans: this.planTokens,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      purchased: this.purchased,
      skip_domain_validation: this.skip_domain_validation,
      comments: this.comments
    };
    if (!params.plans.length) {
      this.postedError = true;
      this.error = 'no plans selected';
      return false;
    }
    this.loading = true;
    postToServer(params).then((response) => {
      this.loading = false;
      const data = response.data;
      if (data.error) {
        this.postedError = true;
        this.error = data.error;
        this.errorRecords = data.records || [];
        return;
      }
      this.postedSuccess = true;

      this.newSubscriptions = data;
    })
      .catch((error) => {
        console.error("failed to get data from server:", error);
        error = error || {};
        this.loading = false;
        this.postedError = true;
        this.error = error.response.data.error || 'unknown';
        if (error.response.data.invalid_domain) {
          this.invalidUsers = error.response.data.users;
        }
        this.errorRecords = [JSON.parse(error.config.data)];

        return;
      });
  }

  onDetachInvalidUsers(evt: any) {
    // evt.preventDefault();
    this.loading = true;
    this.detachedSuccess = false;

    detachInvalidUsersFromSchool(this.schoolId).then((response) => {
      this.loading = false;
      this.postedError = false;
      this.postedSuccess = false;
      this.errorRecords = [];
      this.invalidUsers = [];

      const data = response.data;
      this.detachedSuccess = true;

      this.detachedUsers = data;
    })
      .catch((error) => {
        console.error("failed to detach users from school:", error);
        error = error || {};
        this.loading = false;
        this.postedError = true;
        this.error = error.response.data.error || 'unknown';
        return;
      });
  }

  onReset(evt: any) {
    this.postedError = false;
    this.detachedSuccess = false;
    this.postedSuccess = false;
    this.detachedUsers = [];
    this.invalidUsers = [];
    evt.preventDefault();
    this.loadItems().then(this.loadPlanTokens);
    /* Reset our form values */
    /* Trick to reset/clear native browser form validation state */
    // this.show = false;
    // this.$nextTick(() => { this.show = true });
  }

  stateChange() {
    if (this.oldStateId === this.stateId) {
      return;
    }
    this.oldStateId = this.stateId;
    this.districtId = 0;
    return this.loadItems();
  }

  districtChange() {
    if (this.oldDistrictId === this.districtId) {
      return;
    }
    this.schoolId = 0;
    this.schoolZIP = '';
    this.schoolAddress = '';
    this.oldDistrictId = this.districtId;
    return this.loadItems();
  }

  schoolChange() {
    if (this.oldSchoolId === this.schoolId) {
      return;
    }
    this.oldSchoolId = this.schoolId;
    return this.loadSchoolInfo();
  }

  onSelectionChanged(params: any) {
    this.planTokens = params.selectedRows.map(function (plan: any) {
      return {
        plan_token: plan.plan_token,
        seats: plan.seats
      };
    });
  }

  changedSubscriptionSeats(updatedSub: any) {
    this.planTokens.forEach(function (plan: any) {
      if (plan.plan_token === updatedSub.plan_token) {
        plan.seats = updatedSub.seats;
      }
    });
  }

  loadSchoolInfo() {
    return getSchoolInfo(this.schoolId)
      .then((result) => {
        this.schoolZIP = result.data.zip;
        this.schoolAddress = `${result.data.address}, ${result.data.city}, ${result.data.state}`;
      });
  }


  loadPlanTokens() {
    return loadPlanTokens().then((plansData: any) => {
      this.rows = plansData.data.map(function (plan: any) {
        plan.seats = 5000;
        plan.selected = false;
        return plan;
      });
    });
  }

  loadItems() {
    this.loading = true;
    return getFromServer({districtId: this.districtId, stateId: this.stateId})
      .then(response => {
        this.loading = false;
        const data = response.data;
        this.states = data.states;
        this.districts = data.districts;
        this.schools = data.schools;
        this.domains = data.domains;
        this.schoolId = 0;
        this.schoolZIP = '';
        this.schoolAddress = '';
        this.planTokens = [];
        this.purchased = false;
        this.skip_domain_validation = false;
        this.comments = '';
      })
      .catch((error) => {
        this.loading = false;
        console.error("failed to get data from server:", error);
      });
  }
}
