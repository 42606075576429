
import { Component, Vue } from "vue-property-decorator";
import DomainsList from "@/components/DomainsList.vue"; // @ is an alias to /src

@Component({
  components: {
    DomainsList
  }
})
export default class DomainsView extends Vue {}
