
import { Component, Vue } from "vue-property-decorator";
import Videos from "@/components/Videos.vue"; // @ is an alias to /src

@Component({
  components: {
    Videos
  }
})
export default class VideoList extends Vue {}
