
import { Component, Vue } from "vue-property-decorator";
import HomeschoolSubscriptions from "@/components/HomeschoolSubscriptions.vue"; // @ is an alias to /src

@Component({
  components: {
    HomeschoolSubscriptions
  }
})
export default class HomeschoolSubscriptionsList extends Vue {}
