
import { Component, Vue } from "vue-property-decorator";
import NewSchoolAdmin from "@/components/NewSchoolAdmin.vue"; // @ is an alias to /src

@Component({
  components: {
    NewSchoolAdmin
  }
})
export default class NewSchoolAdminView extends Vue {}
