
import axios from "axios";
import {Component, Vue} from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

interface OptionsItem {
  value: number,
  text: string
}

interface homeschoolForm {
  id: number;
  name: string;
  state_id: string;
  plans: Array<any>;
  subscription_ids: Array<number>;
  teachers: Array<any>
  starts_at: Date;
  ends_at: Date;
  purchase_token: string;
}

function postToServer(params: homeschoolForm) {
  if (params.id === 0) {
    return axios.post("/api/subscriptions/new-homeschool", params);
  } else {
    return axios.post("/api/subscriptions/update-homeschool", params);
  }
}

function getHomeschool(id: number) {
  return axios.get("/api/homeschool-subscription/" + id);
}

function getFromServer() {
  return axios.get("/api/states");
}

function loadPlanTokens() {
  return axios.get("/api/homeschool-plan-tokens");
}

@Component({
  components: {
    Datepicker
  }
})
export default class NewSchoolSubscription extends Vue {
  loading: boolean = false;
  id: any = 0;
  name: string = '';
  states: Array<OptionsItem> = [];
  stateId: string = 'TX';
  startsAt: Date = new Date();
  endsAt: Date = moment().add(1, 'y').toDate();
  seats: number = 0;
  token: string = '';
  plans: Array<OptionsItem> = [];
  planTokens: Array<string> = [];
  subscriptionIds: Array<number> = [];
  postedError: boolean = false;
  detachedSuccess: boolean = false;
  postedSuccess: boolean = false;
  error: string = '';
  errorRecords: Array<any> = [];
  invalidUsers: Array<any> = [];
  detachedUsers: Array<any> = [];
  newSubscriptions: any = [];
  teachers: Array<any> = [{id: 0}];

  data() {
    return {
      loading: false,
      id: 0,
      name: '',
      plans: [],
      stateId: 'TX',
      states: [],
      startsAt: new Date(),
      endsAt: moment().add(1, 'y').toDate(),
      seats: 0,
      token: '',
      planTokens: [],
      subscriptionIds: [],
      show: true,
      postedError: false,
      detachedSuccess: false,
      postedSuccess: false,
      error: '',
      errorRecords: [],
      invalidUsers: [],
      detachedUsers: [],
      newSubscriptions: []
    }
  }

  mounted() {
    return this.loadItems().then(this.loadPlanTokens).then(this.loadHomeschool);
  }

  onSubmit(evt: any) {
    evt.preventDefault();

    this.postedError = false;
    this.detachedSuccess = false;
    this.postedSuccess = false;
    this.errorRecords = [];
    this.invalidUsers = [];
    this.detachedUsers = [];

    const seats = this.seats;

    const plans = this.planTokens.map(function (plan: any) {
      return {
        plan_token: plan,
        seats: seats
      };
    });

    let params = {
      id: this.id,
      name: this.name,
      state_id: this.stateId,
      teachers: this.teachers,
      plans: plans,
      subscription_ids: this.subscriptionIds,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      purchase_token: this.token,
      seats: this.seats
    };
    if (!params.plans.length) {
      this.postedError = true;
      this.error = 'no plan tokens provided';
      return false;
    }
    this.loading = true;
    postToServer(params).then((response) => {
      this.loading = false;
      const data = response.data;
      if (data.error) {
        this.postedError = true;
        this.error = data.error;
        this.errorRecords = data.records || [];
        return;
      }
      this.postedSuccess = true;
      if (data && data.subscriptions && data.subscriptions.length && !this.id) {
        this.id = data.subscriptions[0].school_id;
      }

      this.newSubscriptions = data.subscriptions;
      if (data.purchaseToken) {
        this.token = data.purchaseToken;
      }
    })
      .catch((error) => {
        console.error("failed to get data from server:", error);
        error = error || {};
        this.loading = false;
        this.postedError = true;
        this.error = error.response.data.error || 'unknown';
        if (error.response.data.invalid_domain) {
          this.invalidUsers = error.response.data.users;
        }
        this.errorRecords = [JSON.parse(error.config.data)];

        return;
      });
  }

  addTeacherEmailRow() {
    this.teachers.push({id: 0});
  }

  removeTeacherEmail(rowIndex: number) {
    this.teachers.splice(rowIndex, 1)
  }

  loadHomeschool() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      return getHomeschool(this.id).then((response: any) => {
        let schools = response.data.rows

        if (schools && schools.length && schools[0].school_id == this.id) {
          this.id = schools[0].school_id;
          this.stateId = schools[0].state_id;
          this.name = schools[0].school_name;
          this.teachers = schools[0].teacher_emails.map((em: any) => {
            return {email: em.email, id: em.id};
          });
          this.startsAt = schools[0].starts_at;
          this.endsAt = schools[0].ends_at;
          this.seats = schools[0].seats;
          this.token = schools[0].purchase_token;
          this.planTokens = schools[0].plan_tokens;
          this.subscriptionIds = schools[0].subscription_ids;
        }
      });
    }
  }

  loadPlanTokens() {
    return loadPlanTokens().then((plansData: any) => {
      this.plans = plansData.data.map(function (plan: any) {
        return {
          value: plan.plan_token,
          text: `${plan.name}: ${plan.plan_token}`
        }
      });
    });
  }

  loadItems() {
    this.loading = true;
    return getFromServer()
      .then(response => {
        this.loading = false;
        this.states = response.data;
        this.planTokens = [];
        this.subscriptionIds = [];
      })
      .catch((error) => {
        this.loading = false;
        console.error("failed to get data from server:", error);
      });
  }
}
