
import {Component, Vue} from "vue-property-decorator";
import axios from "axios";

@Component
export default class Nav extends Vue {
  loggedIn: boolean = false;
  userName: string = "";
  permissions: Array<string> = [];

  mounted() {
    axios.get("/api/user").then(response => {
      this.userName = response.data.user.name;
      this.loggedIn = response.data.loggedIn;
      this.permissions = response.data.permissions || [];
    });
  }
}
