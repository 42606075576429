
import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

function getFromServer(params: ServerParams) {
  return axios.get("/api/subscriptions", {params});
}

function postToTheServer(subscription: updatedSubscription) {
  return axios.put("/api/subscriptions/" + subscription.subscription_id, {subscription});
}

interface TableColumns {
  label?: string;
  field?: string;
  filterable?: boolean;
}

interface updatedSubscription {
  subscription_id: number;
  starts_at: string,
  ends_at: string,
  seats: number;
  status: string,
  purchased: string
}

interface ServerParams {
  columnFilters?: object;
  page?: number;
  perPage?: number;
  sort?: {
    field: string;
    type: string;
  };
}

interface VueTableParams extends ServerParams {
  currentPage?: number;
  currentPerPage?: number;
  columnIndex: number;
  sortType?: "string";
}

@Component({
  components: {
    Datepicker
  }
})
export default class Subscriptions extends Vue {
  totalRecords: number = 0;
  postedSuccess: boolean = false;
  postedError: boolean = false;
  postedMessage: string = '';
  totalRows: number = 0;
  rows: Array<object> = [];
  columns: Array<TableColumns> = [];
  serverParams: VueTableParams = {columnIndex: 1};

  mounted() {
    this.loadItems();
  }

  data() {
    return {
      columns: [
        {
          label: "Level",
          field: "level",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "District",
          field: "district_names",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Domains",
          field: "domains",
          filterable: false,
          filterOptions: {
            trigger: 'enter',
            enabled: false
          }
        },
        {
          label: "School Names",
          field: "school_names",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "School Ids",
          field: "school_ids",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Plan Token",
          field: "plan_token",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Admin",
          field: "admin_emails",
          sortable: false,
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Start Date",
          field: "starts_at",
          filterable: true
        },
        {
          label: "End Date",
          field: "ends_at",
          filterable: true
        },
        {
          label: "Seats",
          field: "seats",
          filterable: true
        },
        {
          label: "Subscription Id",
          field: "subscription_id",
          filterable: true,
          filterOptions: {
            trigger: 'enter',
            enabled: true
          }
        },
        {
          label: "Purchased",
          field: "purchased",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'all',
            filterDropdownItems: [
              {value: 0, text: 'no'},
              {value: 1, text: 'yes'}
            ]
          }
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          filterOptions: {
            enabled: true
          }
        }
      ],
      rows: [],
      totalRecords: 0,
      postedSuccess: false,
      postedError: false,
      postedMessage: '',
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: ""
        },
        page: 1,
        perPage: 10
      }
    };
  }

  changedSubscriptionRecord(updatedSub: any, dateUpdate: any) {
    this.postedSuccess = false;
    this.postedError = false;
    this.postedMessage = '';
    const setToActive = updatedSub.starts_at && updatedSub.ends_at && (
      moment(updatedSub.starts_at).isSameOrBefore(moment()) && moment(updatedSub.ends_at).isSameOrAfter(moment())
    );
    if (dateUpdate && setToActive) {
      updatedSub.status = 'active';
    }
    console.log("subscription update:", updatedSub, setToActive);
    return postToTheServer({
      subscription_id: updatedSub.subscription_id,
      starts_at: updatedSub.starts_at,
      ends_at: updatedSub.ends_at,
      seats: updatedSub.seats,
      status: updatedSub.status,
      purchased: updatedSub.purchased
    })
      .then((res) => {
        this.postedSuccess = true;
        this.postedError = false;
        this.postedMessage = JSON.stringify(res.data);
      })
      .catch((res) => {
        this.postedSuccess = false;
        this.postedError = true;
        this.postedMessage = JSON.stringify(res.data);
      });
  }

  updateParams(newProps: any) {
    this.serverParams = Object.assign({}, this.serverParams, newProps);
  }

  onPageChange(params: VueTableParams) {
    this.updateParams({page: params.currentPage});
    this.loadItems();
  }

  onPerPageChange(params: VueTableParams) {
    this.updateParams({perPage: params.currentPerPage, page: 1});
    this.loadItems();
  }

  onSortChange(params: VueTableParams) {
    this.updateParams({
      sort: {
        type: params.sortType,
        field: this.columns[params.columnIndex].field
      },
      page: 1
    });
    this.loadItems();
  }

  onColumnFilter(params: VueTableParams) {
    params.page = 1;
    this.updateParams(params);
    this.loadItems();
  }

  // load items is what brings back the rows from server
  loadItems() {
    getFromServer(this.serverParams)
      .then(response => {
        console.log("RESP:", response.data);
        this.totalRecords = response.data.totalRecords;
        this.totalRows = response.data.totalRecords;
        this.rows = response.data.rows;
      })
      .catch(function (error) {
        console.error("failed to get data from server:", error);
      });
  }
}
