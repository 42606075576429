
import { Component, Vue } from "vue-property-decorator";
import Subscriptions from "@/components/Subscriptions.vue"; // @ is an alias to /src

@Component({
  components: {
    Subscriptions
  }
})
export default class SubscriptionsList extends Vue {}
