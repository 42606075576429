
import { Component, Vue } from "vue-property-decorator";
import NewSchoolSubscription from "@/components/NewSchoolSubscription.vue"; // @ is an alias to /src

@Component({
  components: {
    NewSchoolSubscription
  }
})
export default class NewSchoolSubscriptionView extends Vue {}
