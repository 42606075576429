
import axios from "axios";
import {Component, Vue} from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";

interface OptionsItem {
  value: number,
  text: string
}

interface videoRecord {
  id: number;
  name: string,
  description: string,
  active: number,
  lms_id: number,
  subject_id: number,
  state_id: number,
  subscribed: number,
  admin: number,
  video_url: string,
  video_thumb: string
}

function getOptionsFromServer() {
  return axios.get("/api/lms-subject-state");
}

function getFromServer() {
  return axios.get("/api/videos");
}

function createVideoAPI(video: videoRecord) {
  return axios.post("/api/videos", {video});
}

function updateVideoAPI(video: videoRecord) {
  return axios.put("/api/videos/" + video.id, {video});
}

function deleteVideoAPI(video: videoRecord) {
  return axios.delete("/api/videos/" + video.id);
}

function notifyVideoAPI(video: videoRecord) {
  return axios.post("/api/videos/" + video.id + "/notify");
}

@Component({
  components: {
    Datepicker
  }
})
export default class Videos extends Vue {
  loading: boolean = false;
  lmss: Array<OptionsItem> = [];
  subjects: Array<OptionsItem> = [];
  states: Array<OptionsItem> = [];
  videos: Array<videoRecord> = [];
  postedError: boolean = false;
  postedErrorMessage: string = '';
  detachedSuccess: boolean = false;
  postedSuccess: boolean = false;
  error: string = '';
  errorRecords: Array<any> = [];
  fields: Array<any> = [];
  newRecord: videoRecord = {
    id: 0,
    lms_id: 0,
    subject_id: 0,
    state_id: 0,
    video_url: '',
    video_thumb: '',
    description: '',
    name: '',
    active: 1,
    subscribed: 0,
    admin: 0
  };
  createdRecord: any = {};

  data() {
    return {
      loading: false,
      postedError: false,
      postedErrorMessage: '',
      detachedSuccess: false,
      postedSuccess: false,
      error: '',
      errorRecords: [],
      fields: [
        {key: 'id', label: 'Id'},
        {key: 'name', label: 'Name'},
        {key: 'description', label: 'Description'},
        {key: 'active', label: 'Active'},
        {key: 'subscribed', label: 'Subscribed'},
        {key: 'admin', label: 'Admin'},
        {key: 'lms_id', label: 'Lms'},
        {key: 'subject_id', label: 'Subject'},
        {key: 'state_id', label: 'State'},
        {key: 'video_url', label: 'Video URL'},
        {key: 'video_thumb', label: 'Video Thumbnail'},
        {key: 'edit', label: 'Edit'},
        {key: 'delete', label: 'Delete'},
        {key: 'notify', label: 'Notify'}
      ],
      newRecord: {},
      createdRecord: {}
    }
  }

  mounted() {
    return this.loadItems().then(() => this.loadVideos());
  }

  onReset(evt?: any) {
    this.postedError = false;
    this.postedErrorMessage = '';
    this.detachedSuccess = false;
    this.postedSuccess = false;
    this.newRecord = {
      id: 0,
      lms_id: 0,
      subject_id: 0,
      state_id: 0,
      video_url: '',
      video_thumb: '',
      description: '',
      name: '',
      active: 1,
      subscribed: 0,
      admin: 0
    };
    this.createdRecord = {};
    if (evt) {
      evt.preventDefault();
    }
    return this.loadVideos();
  }

  resetNewVideo(evt?: any) {
    this.postedError = false;
    this.postedErrorMessage = '';
    this.detachedSuccess = false;
    this.postedSuccess = false;
    this.newRecord = {
      id: 0,
      lms_id: 0,
      subject_id: 0,
      state_id: 0,
      video_url: '',
      video_thumb: '',
      description: '',
      name: '',
      active: 1,
      subscribed: 0,
      admin: 0
    };
    this.createdRecord = {};
  }

  deleteVideo(video: videoRecord) {
    this.loading = true;
    this.postedError = false;
    return deleteVideoAPI(video)
      .then(() => {
        this.loading = false;
        this.postedError = false;
        return this.loadVideos();
      })
      .catch((error: any) => {
        this.postedSuccess = false;
        this.loading = false;
        console.error("ERROR IN DELETING DOMAIN:", error);
        this.postedError = true;
        this.postedErrorMessage = error.data;
        return this.loadVideos();
      });
  }

  updateVideo(video: videoRecord) {
    this.loading = true;
    this.postedError = false;
    return updateVideoAPI(video)
      .then(() => {
        this.loading = false;
        this.postedError = false;
        return this.loadVideos();
      })
      .catch((error: any) => {
        this.postedSuccess = false;
        this.loading = false;
        console.error("ERROR IN UPDATING DOMAIN:", error);
        this.postedError = true;
        this.postedErrorMessage = error.data;
        return this.loadVideos();
      });
  }

  notifyVideo(video: videoRecord) {
    this.loading = true;
    this.postedError = false;
    return notifyVideoAPI(video)
      .then(() => {
        this.loading = false;
        this.postedError = false;
      })
      .catch((error: any) => {
        this.postedSuccess = false;
        this.loading = false;
        console.error("ERROR IN UPDATING DOMAIN:", error);
        this.postedError = true;
        this.postedErrorMessage = error.data;
      });
  }

  createVideo() {
    this.loading = true;
    this.postedError = false;
    return createVideoAPI(this.newRecord)
      .then((response) => {
        this.postedSuccess = true;
        this.postedError = false;
        this.loading = false;
        this.createdRecord = response.data;
        return this.loadVideos();
      })
      .catch((error: any) => {
        this.postedSuccess = false;
        this.loading = false;
        console.error("ERROR IN CREATING DOMAIN:", error);
        this.postedError = true;
        this.postedErrorMessage = error.data;
        return this.loadVideos();
      });
  }

  loadItems() {
    return getOptionsFromServer()
      .then(response => {
        const data = response.data;
        this.lmss = [{value: 0, text: 'no associated lms'}].concat(data.lmss || []);
        this.subjects = [{value: 0, text: 'no associated subject'}].concat(data.subjects || []);
        this.states = [{value: 0, text: 'no associated state'}].concat(data.states || []);
      })
      .catch((error) => {
        console.error("failed to get options from server:", error);
      });
  }

  loadVideos() {
    this.loading = true;
    return getFromServer()
      .then((response: any) => {
        this.loading = false;
        this.videos = response.data;
      })
      .catch((error: any) => {
        this.loading = false;
        console.error("failed to get data from server:", error);
      });
  }
}
