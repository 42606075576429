
import axios from "axios";
import {Component, Vue} from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";


interface OptionsItem {
  value: number,
  text: string
}

interface newAdminForm {
  school_ids: Array<number>;
  district_id: number;
  plan_tokens?: Array<string>;
  starts_at?: Date;
  ends_at?: Date;
  purchased?: boolean;
  comments?: string;
  admin_emails: Array<string>;
  subscription_ids: Array<number>;
}

interface DistrictAndSchoolsParams {
  stateId: string;
  districtId: number;
}

interface GetSchoolAdminsParams {
  schoolIds: Array<number>;
}

interface SchoolSubscription {
  school_ids: Array<any>;
  admins: Array<any>;
  subscription_id: number;
  starts_at: string;
  ends_at: string;
  purchased: boolean;
  comments: string;
  kind: string;
  status: string;
  plan_token: string;
  plan_name: string;
}

function postToServer(params: newAdminForm) {
  return axios.post("/api/new-district-admins", params);
}

function getDistrictsAndSchools(params: DistrictAndSchoolsParams) {
  return axios.get("/api/districts-and-schools", {params});
}

function getSchoolsInfo(params: GetSchoolAdminsParams) {
  return axios.post(`/api/admins-of-school/multiple`, {school_ids: params.schoolIds});
}

function loadSummaryPlansTokens() {
  return axios.get("/api/summary-plan-tokens");
}

function loadPlanTokens() {
  return axios.get("/api/all-plan-tokens");
}

function checkFutureAdminsOnBackEnd(adminRecords: any) {
  return axios.post("/api/check-future-admins", {admins: adminRecords})
    .then(function (response) {
      return response.data;
    });
}

@Component({
  components: {
    Datepicker
  }
})
export default class NewDistrictAdmin extends Vue {
  loading: boolean = false;
  schoolIds: Array<number> = [];
  oldSchoolIds: Array<number> = [];
  states: Array<OptionsItem> = [];
  stateId: string = 'TX';
  oldStateId: string = 'TX';
  districts: Array<OptionsItem> = [];
  districtId: number = 0;
  oldDistrictId: number = 0;
  schools: Array<OptionsItem> = [];
  domains: Array<string> = [];
  startsAt: Date = new Date();
  endsAt: Date = new Date();
  plans: Array<OptionsItem> = [];
  planTokens: Array<string> = [];
  selectedSubscriptions: Array<number> = [];
  purchased?: boolean = false;
  comments?: string = '';
  postedError: boolean = false;
  postedSuccess: boolean = false;
  error: string = '';
  errorRecords: any;
  newSubscriptions: any = [];
  schoolSubscriptions: Array<SchoolSubscription> = [];
  futureAdmins: Array<any> = [{}];
  forceSubmitLock: boolean = false;

  data() {
    return {
      loading: false,
      domains: [],
      plans: [],
      districtId: 0,
      stateId: 'TX',
      states: [],
      selectedSchool: 0,
      schoolIds: [],
      oldSchoolIds: [],
      districts: [],
      schools: [],
      startsAt: new Date(),
      endsAt: new Date(),
      planTokens: [],
      selectedSubscriptions: [],
      purchased: false,
      comments: '',
      show: true,
      postedError: false,
      postedSuccess: false,
      error: '',
      errorRecords: null,
      newSubscriptions: [],
      schoolSubscriptions: [],
      futureAdmins: [{}]
    }
  }

  get isReadyToSubmit() {
    const adminsAreReady = this.futureAdmins.filter(function (admin) {
      return admin.id || admin.pending;
    }).length > 0;
    const subscriptionsAreReady = this.selectedSubscriptions.length > 0;
    const schoolIdIsReady = this.schoolIds.length;
    return !(adminsAreReady && subscriptionsAreReady && schoolIdIsReady) && this.forceSubmitLock;
  }

  mounted() {
    return this.loadItems().then(this.loadPlanTokens);
  }

  addFutureAdminRow() {
    this.futureAdmins.push({});
  }

  removeFutureAdminRow(rowIndex: number) {
    this.futureAdmins.splice(rowIndex, 1)
  }

  checkFutureAdmins() {
    this.postedError = false;
    checkFutureAdminsOnBackEnd(this.futureAdmins)
      .then((admins: any) => {
        if (admins.error) {
          this.postedError = true;
          this.error = admins.error;
          return;
        }
        this.futureAdmins = admins;
      })
      .catch((res: any) => {
        this.postedError = true;
        this.error = res.error;
      });
  }

  onSubmit(evt: any) {
    evt.preventDefault();

    this.postedError = false;
    this.postedSuccess = false;
    this.errorRecords = null;
    this.forceSubmitLock = true;

    const params = {
      school_ids: this.schoolIds,
      district_id: this.districtId,
      plan_tokens: this.planTokens,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      purchased: this.purchased,
      comments: this.comments,
      subscription_ids: this.selectedSubscriptions,
      admin_emails: [...new Set(this.futureAdmins.map((a) => a.email))].filter((r) => r)
    };
    this.loading = true;
    postToServer(params).then((response) => {
      this.loading = false;
      const data = response.data;
      if (data.error && data.error.records) {
        this.error = 'Duplicate Records';
        this.postedError = true;
        this.errorRecords = data.error.records;
        return;
      }
      if (data.error && !data.error.records) {
        this.error = data.error;
        this.postedError = true;
        this.forceSubmitLock = false;
        return;
      }
      this.postedSuccess = true;

      this.newSubscriptions = data;

      this.forceSubmitLock = false;
    })
      .catch((error) => {
        console.log("WEIRD ERROR:", error);
        this.error = error;
        this.postedError = true;
        this.forceSubmitLock = false;
      });
  }

  onReset(evt: any) {
    this.postedError = false;
    this.postedSuccess = false;
    evt.preventDefault();
    this.loadItems().then(this.loadPlanTokens);
    /* Reset our form values */
    /* Trick to reset/clear native browser form validation state */
    // this.show = false;
    // this.$nextTick(() => { this.show = true });
  }

  stateChange() {
    if (this.oldStateId === this.stateId) {
      return;
    }
    this.oldStateId = this.stateId;
    this.districtId = 0;
    return this.loadItems();
  }

  districtChange() {
    if (this.oldDistrictId === this.districtId) {
      return;
    }
    this.schoolIds = [];
    this.oldDistrictId = this.districtId;
    return this.loadItems();
  }

  schoolChange() {
    if (this.oldSchoolIds.join('|') === this.schoolIds.join('|')) {
      return;
    }
    this.oldSchoolIds = this.schoolIds;
    this.selectedSubscriptions = [];
    return this.loadSchoolInfo();
  }

  loadSchoolInfo() {
    return getSchoolsInfo({schoolIds: this.schoolIds})
      .then((result) => {
        this.schoolSubscriptions = result.data;
      });
  }

  loadPlanTokens() {
    return loadPlanTokens()
      .then((plans: any) => {
        return plans.data;
      })
      .then((plansData: any) => {
        this.plans = plansData.map(function (plan: any) {
          return {
            value: plan.plan_token,
            text: `${plan.name}: ${plan.plan_token}`
          }
        });
      });
  }

  loadItems() {
    this.loading = true;
    return getDistrictsAndSchools({districtId: this.districtId, stateId: this.stateId})
      .then(response => {
        this.loading = false;
        const data = response.data;
        this.states = data.states;
        this.districts = data.districts;
        this.schools = data.schools;
        this.domains = data.domains;
        this.schoolIds = data.schools.map((s: any) => s.value);
        this.planTokens = [];
        this.selectedSubscriptions = [];
        this.purchased = false;
        this.comments = '';
      })
      .catch((error) => {
        this.loading = false;
        console.error("failed to get data from server:", error);
      });
  }
}
