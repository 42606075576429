
import { Component, Vue } from "vue-property-decorator";
import NewHomeschoolSubscription from "@/components/NewHomeschoolSubscription.vue"; // @ is an alias to /src

@Component({
  components: {
    NewHomeschoolSubscription
  }
})
export default class NewHomeschoolSubscriptionView extends Vue {}
